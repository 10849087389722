@import './styles/About.scss';
@import './styles/Header.scss';

body {
  margin: 0;
  padding: 0;
}

section {
  padding: 3rem 0rem;
}