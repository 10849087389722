#section-about {
    min-height: 40vh;
    height: 50vh;
    display: flex;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 0px;
    text-align: center;

    .title {
        font-size: 6rem;
        letter-spacing: 2px;
        font-weight: 700;
        margin-top: 100px;
        text-align: center;
    }

    a {
        font-size: inherit;
    }

    @media only screen and (max-width: 768px) {
        .mantine-RingProgress-root {
            width: auto !important;
            height: auto !important;
        }

        .center-mobile {
            text-align: center;
        }
    }
}